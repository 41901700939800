import { defineStore } from 'pinia';
import axios from 'axios';

export const usePropertiesStore = defineStore('propertiesStore', {
  state: () => ({
    allProperties: {},
    propertiesData: [],
    isLoading: false,
    totalItems1: 0,
    currentPage: 1,
    itemsPerPage: 16,

    filter: {
      sell:0,
      forRent:0,
      types: '',
      region: '',
      subregion: '',
      furnishing: '',
      priceFrom: '',
      priceTo: '',
      surfaceFrom: '',
      surfaceTo: '',
      floorFrom: '',
      floorTo: '',
      build: '',
    }
  }),

  actions: {
    async fetchPropertiesData() {
      this.isLoading = true;
      try {
        this.propertiesData = [];
        
        if( this.filter.region==null) this.filter.region='';
        if( this.filter.subregion==null) this.filter.subregion='';
        if( this.filter.estateType==null) this.filter.estateType='';
        if( this.filter.furnish==null) this.filter.furnish='';
        if( this.filter.buildType==null) this.filter.buildType='';

        const filter1={
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          sell: this.filter.sell,
          forRent: this.filter.forRent,
          types: this.filter.estateType.toString(),
          region: this.filter.region.toString(),
          subregion: this.filter.subregion.toString(),          
          priceFrom: this.filter.minPrice,
          priceTo: this.filter.maxPrice,
          surfaceFrom: this.filter.sizeFrom,
          surfaceTo: this.filter.sizeTo,
          floorFrom: this.filter.floorNumberFrom,
          floorTo: this.filter.floorNumberTo,
          buildTypes: this.filter.buildType.toString(),
          furnishing: this.filter.furnish.toString(),
        }

        const response = await axios.post("https://api.estateassistant.eu/api/portals/LoadEstates", filter1);        
        this.allProperties = response.data;
        this.propertiesData = response.data.items;
        this.totalItems1 = response.data.totalItems;
         this.currentPage = response.data.currentPage;
        // this.itemsPerPage = response.data.itemsPerPage;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    clearStoreFilter() {
      this.filter = {
        sell: 0,
        forRent: 0,
        types: '',
        region: '',
        subregion: '',
        furnishing: '',
        build: '',
        priceFrom: '',
        priceTo: '',
        surfaceFrom: '',
        surfaceTo: '',
        floorFrom: '',
        floorTo: '',
      }
    },

    async fetchHomePage() {
      this.isLoading = true;
      try {
        const filter={
          sell: 1,
          forRent: 0,
          types: '',
          region: '',
          subregion: '',
          furnishing: '',
          priceFrom: '',
          priceTo: '',
          surfaceFrom: '',
          surfaceTo: '',
          floorFrom: '',
          floorTo: '',
          build: '',
        }

        const response = await axios.post("https://api.estateassistant.eu/api/portals/LoadEstates", filter);        
        this.allProperties = response.data;
        this.propertiesData = response.data.items;
        // this.currentPage = response.data.currentPage;
        // this.itemsPerPage = response.data.itemsPerPage;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },

  getters: {
    totalItems() {
      return this.totalItems1;
    },

    takeFilter() {
      return this.filter;
    },

    paginatedProperties() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.propertiesData.slice(start, end);
    },

  },
  persist: true,
});