<!-- Advanced search component. -->
<template>
  <div class="advanced-search-wrapper">
    <div class="left-sidebar">
      <div class="filter-cards">
        <div class="advance-card">
          <div class="advance-card__top">
            <span class="advance-card__title">Търси имот:</span>
            <span class="advance-card__close"
              ><el-icon @click="hideDrawer"> <Close /> </el-icon
            ></span>
          </div>
        </div>
        <div class="advance-card">
          <h6>Филтрирай по:</h6>
          <div class="red-fragment"></div>
        </div>
        <!-- Search form-fields starts here -->
        <div class="advance-search-card">
          <form @submit.prevent="handleSearch">
            <!-- Select region field -->
            <div class="form-component-wrapper">
              <label for="regions">Регион в страната</label>
              <el-select
                v-model="filter.region"
                clearable
                :filterable="true"
                placeholder="Региони"
                style="width: 100%; margin-top: 15px"
                id="regions"
                size="large"
                no-data-text="Няма данни"
                no-match-text="няма съвпадения"
                @change="loadSubRegions()"
              >
                <el-option
                  v-for="item in regions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="options"
                />
              </el-select>
            </div>

            <!-- Select Subregion field -->
            <div class="form-component-wrapper">
              <label for="subregion">Избери подрегион</label>
              <el-select
                v-model="filter.subregion"
                id="subregion"
                :filterable="true"
                multiple
                placeholder="Подрегиони"
                style="width: 100%; margin: 15px 0"
                size="large"
                no-data-text="Няма данни"
                no-match-text="няма съвпадения"
              >
                <el-option
                  v-for="item in subregions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="options"
                />
              </el-select>
            </div>

            <!-- Select property type field -->
            <div class="form-component-wrapper">
              <label for="type">Избери тип имот</label>
              <el-select
                v-model="filter.estateType"
                id="type"
                multiple
                placeholder="Избери"
                style="width: 100%; margin: 15px 0"
                size="large"
              >
                <el-option
                  v-for="item in estateTypes"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                  class="options"
                />
              </el-select>
            </div>

            <!-- Price range in EUR / two number fields grouped in one div (min and max values) -->
            <div class="form-content-wrapper">
              <label for="priceRange">Цена в Евро</label>
              <div class="number-inputs-group">
                <el-input
                  v-model.number="filter.minPrice"
                  id="priceRange"
                  type="number"
                  size="large"
                  placeholder="От"
                  min="0"
                  style="width: 40%; margin: 15px 0"
                />
                -
                <el-input
                  v-model.number="filter.maxPrice"
                  type="number"
                  size="large"
                  placeholder="До"
                  min="0"
                  input-style="width: 40%"
                />
              </div>
            </div>

            <!-- Price per Sqm / two number fields grouped in one div (min and max values) -->
            <div class="form-content-wrapper" v-if="1 == 0">
              <label for="pricePerSqm">Цена на м2</label>
              <div class="number-inputs-group">
                <el-input
                  v-model.number="filter.minPricePerSqm"
                  id="pricePerSqm"
                  type="number"
                  size="large"
                  placeholder="От"
                  min="0"
                  style="width: 40%; margin: 15px 0"
                />
                -
                <el-input
                  v-model.number="filter.maxPricePerSqm"
                  type="number"
                  size="large"
                  placeholder="До"
                  min="0"
                  input-style="width: 40%"
                />
              </div>
            </div>

            <!-- Floor from-to / two number fields grouped in one div (min and max values) -->
            <div class="form-content-wrapper">
              <label for="floorNumber">Етаж</label>
              <div class="number-inputs-group">
                <el-input
                  v-model.number="filter.floorNumberFrom"
                  id="floorNumber"
                  type="number"
                  size="large"
                  placeholder="От"
                  min="0"
                  style="width: 40%; margin: 15px 0"
                />
                -
                <el-input
                  v-model.number="filter.floorNumberTo"
                  type="number"
                  size="large"
                  placeholder="До"
                  min="0"
                  input-style="width: 40%"
                />
              </div>
            </div>

            <!-- Size in Sqm from-to / two number fields grouped in one div (min and max values)-->
            <div class="form-content-wrapper">
              <label for="size">Площ в м2</label>
              <div class="number-inputs-group">
                <el-input
                  v-model.number="filter.sizeFrom"
                  id="size"
                  type="number"
                  size="large"
                  placeholder="От"
                  min="0"
                  style="width: 40%; margin: 15px 0"
                />
                -
                <el-input
                  v-model.number="filter.sizeTo"
                  type="number"
                  size="large"
                  placeholder="До"
                  min="0"
                  input-style="width: 40%"
                />
              </div>
            </div>

            <!-- Select building type field -->
            <div class="form-component-wrapper">
              <label for="buildingType">Избери тип строителство</label>
              <el-select
                v-model="filter.buildType"
                id="BuildTypes"
                multiple
                placeholder="Избери"
                style="width: 100%; margin: 15px 0"
                size="large"
              >
                <el-option
                  v-for="item in buildTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="options"
                />
              </el-select>
            </div>

            <!-- Select furnishing type field -->
            <!-- <div class="form-component-wrapper">
              <label for="heatinType">Избери обзавеждане</label>
              <el-select
                v-model="filter.furnish"
                id="furnitureType"
                multiple
                placeholder="Избери"
                style="width: 100%; margin: 15px 0"
                size="large"
              >
                <el-option
                  v-for="item in furnishing"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="options"
                />
              </el-select>
            </div> -->

            <!-- Submit OR clear filter buttons -->
            <div class="submit-filter">
              <span @click="clearFilter"
                ><el-icon>
                  <CloseBold />
                </el-icon>
                Изчисти
              </span>
              <!-- Perform search or hide drawer button -->
              <button class="green-to-transparent-outlined btn-medium" @click="filterProperties">
                Търси
              </button>
            </div>
          </form>
        </div>
        <!-- Search form ends here -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { usePropertiesStore } from "../../stores/PropertiesStore";

export default {
  props: ["hideDrawer"],

  data() {
    return {
      regions: [],
      subregions: [],
      estateTypes: [],
      buildTypes: [],
      furnishing: [],
    };
  },

  methods: {
    async fetchNomenclatures() {
      try {
        const response = await axios.get(
          "https://api.estateassistant.eu/api/portals/GetNomenclatures"
        );
        const nomenclatures = response.data.data;

        // Extract and assign data to state variables
        for (const item of nomenclatures) {
          if (item.name === "EstateTypes") {
            this.estateTypes = item.value;
          } else if (item.name === "Regions") {
            this.regions = item.value;
          } else if (item.name === "BuildTypes") {
            this.buildTypes = item.value;
          } else if (item.name === "Furnishings") {
            this.furnishing = item.value;
          }
        }
      } catch (error) {
        console.error("Failed to fetch", error);
      }
    },

    async fetchSubregions(id) {
      if (id === null) return;
      try {
        const response = await axios.get(
          `https://api.estateassistant.eu/api/portals/GetFilterSubRegions?region=${id}`
        );
        const subregions = response.data;
        this.subregions = subregions.SubRegions;
        // console.log("Subregions data has been assigned to the state variable");
      } catch (error) {
        console.error("Failed to fetch subregions", error);
      }
    },

    loadSubRegions() {
      this.subregions = null;      
      
      if (this.filter.region) {
        this.fetchSubregions(this.filter.region);
      }
    },

    filterProperties() {
      const propertiesStore = usePropertiesStore();
        propertiesStore.fetchPropertiesData();

        if (window.innerWidth > 1024) {
          return;
        } else {
          this.hideDrawer();
        }
    },

    clearFilter() {
      const propertiesStore = usePropertiesStore();
      propertiesStore.clearStoreFilter();
    },

    initFilter() {
      this.loadSubRegions();
    },
  },

  computed: {
    filter() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.takeFilter;
    },
  },

  mounted() {
    const propertiesStore = usePropertiesStore();
    this.fetchNomenclatures();
    propertiesStore.filterData;
    this.initFilter();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";

// this style is <el-option> component inner style, so it should be left here.
.options {
  height: 40px;
  color: black;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  margin: 5px;

  &:hover {
    background: #f1f1f1;
    color: #00bf63;
    padding-left: 10px;
  }
}
</style>
