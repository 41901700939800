<template>
  <div class="top-infobar-wrapper">
    <p>Открити са <span style="color: #00bf63"> {{ totalItems }}</span> имота в категорията.</p>
  </div>
</template>

<script>
  import { usePropertiesStore } from "../../stores/PropertiesStore";
export default {
  props: ["data", "itemsPerPage", "currentPage"],

  computed: {
    totalItems() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.totalItems;
},
  }
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
span {
  color: var(--theme-default3);
}
</style>
