<template>
  <ul class="desktop-navigation">
    <router-link to="/"><li> Начало</li></router-link>
    <!-- <router-link to="/new-buildings"><li>Ново строителство</li></router-link> -->
    <router-link to="/for-sale"><li>Продава</li></router-link>
    <router-link to="/for-rent"><li>Отдава под наем</li></router-link>
    <router-link to="/about-us"><li>За сайта</li></router-link>
  </ul>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
a.router-link-active {
  color: #3f7489;
  background: #f1f1f1;
}
</style>
