<template>
<div>
    <h1>Имоти за продажба</h1>
    <div>
        <sales-listing></sales-listing>
    </div>
</div>
</template>

<script>
import SalesListing from '../layouts/SalesListing.vue'

export default {
    components: {
        SalesListing,
        
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";

h1 {
  margin: 50px 10px 30px 10px;
  padding-bottom: 10px;
  color: transparent;
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  color: #00bf63;
  border-bottom: 1px solid #fff;
  transition: all .5s;
  &:hover {
    border-bottom: 1px solid #00bf63;
    box-shadow: 0 70px 70px rgba(0, 0, 0.2, 0.2);
    transform: translate(0, -10);
    z-index: 2;
  }
}
</style>