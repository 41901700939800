<template>
  <div class="quick-review-wrapper">
    <div class="property-box">
      <div class="image-wrapper">
        <router-link :to="'/property/' + id"><img :src="pictures" :alt="property" /></router-link>
        <div class="price-tag"><router-link :to="'/property/' + id"> {{ price }} {{ priceTag }} </router-link> </div>
      </div>
      <div class="property-details">
        <ul>
          <li>
            <span>
              <i class="material-icons">apartment</i>
            </span>
            Местоположение: {{ property }}
          </li>
          <li>
            <span>
              <el-icon><Location /></el-icon
            ></span>
            Регион: {{ address }}
          </li>
          <li>
            <span>
              <i class="material-icons">meeting_room</i></span>
            Вид на имота: {{ propertyType }}
          </li>
          <li>
            <span>
              <i class="material-icons">zoom_out_map</i>
            </span>
            Големина на имота: {{ areaSize }} м2
          </li>
          <li>
            <span>
              <el-icon><Sort /></el-icon
            ></span>
            На етаж: {{ onFloor }}
          </li>
          <li>
            <span>
              <el-icon><Select /></el-icon
            ></span>
            Имотът се {{ offerType }}
          </li>
        </ul>
      </div>

      <div>
        <router-link :to="'/property/' + id"><button class="green-to-transparent-outlined btn-medium"> Разгледай </button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "pictures",
    "property",
    "address",
    "offerType",
    "propertyType",
    "priceInEur",
    "onFloor",
    "areaSize",
  ],
  computed: {
    price() {
      const formattedPrice = this.priceInEur.toLocaleString("EU-eu", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
      return formattedPrice;
    },
    priceTag() {
      if (this.price === "Провери...") {
        return ""
      } if (this.price.length <= 5 ) {
        return "EUR / месец"
      }else {
        return "EUR"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
i {
  color: #f35d43
}
</style>
