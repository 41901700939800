<template>
  <!-- The homepage carousel! -->
  <div class="carousel-wrapper">
    <h2>Земя, къща, парцел или жилище? Открийте имот във всяко кътче на България!</h2>
    <el-carousel :interval="5000" arrow="always" height="100vh">
    <el-carousel-item v-for="item in backgrounds" :key="item">
      <h3 class="carousel-desc"><img :src="item" alt="item"/></h3>
    </el-carousel-item>
  </el-carousel>
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <base-loading v-if="isLoading"></base-loading>
    <recommended-properties></recommended-properties>
  </div>
</template>

<script>
import { usePropertiesStore } from "../stores/PropertiesStore";
import RecommendedProperties from "./RecommendedProperties.vue";
export default {
  data() {
    return {
      backgrounds: [
        "https://images.unsplash.com/photo-1653112521175-c88089ff4757?q=80&w=2094&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1414187976819-32a4deaae820?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1593616041952-cf319157f89d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1668013714349-72bf26e967fd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1440151050977-247552660a3b?q=80&w=1990&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ]
    }
  },
  components: {
    RecommendedProperties,
  },

  computed: {
    isLoading() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.isLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
